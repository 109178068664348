(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:TownhallEntities
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('TownhallEntities', TownhallEntities);

  function TownhallEntities($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/ajuntaments/:id/entitats', {id: '@_id'}, {
      update: {
        method: 'PUT'
      }
    });
  }
}());
